import { Atom } from ":mods";
import { ASubmoduleMaterial } from "../models";
import { MATERIAL_TYPES } from "../const";
export function createMaterialInputs(details?: ASubmoduleMaterial) {
  const ID = Atom.Form.createInput(undefined, { disabled: true });
  const Type = Atom.Form.createInput(MATERIAL_TYPES.lesson, { required: true, disabled: true });
  const Name = Atom.Form.createInput(details?.name, { required: true });
  const Length = Atom.Form.createInput(details?.name, { required: true });
  const Submodule = Atom.Form.createInput(details?.name, { required: true });
  const DesignName = Atom.Form.createInput(details?.design_name, { required: true });
  const Order = Atom.Form.createInput(details?.order, { required: true });
  const MaxElements = Atom.Form.createInput(details?.max_elements, { required: true });
  const Description = Atom.Form.createInput(details?.description, { required: true });
  const ShortDescription = Atom.Form.createInput(details?.short_description, { required: true });
  const Actions = Atom.Form.createFormActions(
    {
      ID,
      Type,
      Name,
      Length,
      Submodule,
      DesignName,
      Order,
      MaxElements,
      Description,
      ShortDescription,
    },
    {
      defaultCase: "snake",
    }
  );

  // const values = Actions.getValuesUppercase({TotalMinutesQuiz: "kebab", TotalMinutesVideo: "snake"});
  return {
    Actions,
    ID,
    Type,
    Name,
    Length,
    Submodule,
    DesignName,
    Order,
    MaxElements,
    Description,
    ShortDescription,
  };
}
